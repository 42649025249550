import { differenceInDays } from 'date-fns/differenceInDays';
import { BookingItem, TentTypeV1, TentTypeV1Prices } from '..';
import {
  getBathingDaysInInRange as getBathingDaysInRange,
  parseDate,
} from './booking-configuration-dates';
import { capacityV1 } from './booking-configuration-v1';
import { CapacityCount } from './booking-configuration-types';

export const getPrice = (tentType: TentTypeV1) => {
  switch (tentType) {
    case TentTypeV1.TWIN_ROOM:
      return TentTypeV1Prices.TWIN_ROOM;
    case TentTypeV1.FAMILY_ROOM:
      return TentTypeV1Prices.FAMILY_ROOM;
    case TentTypeV1.FAMILY_ROOM_PLUS:
      return TentTypeV1Prices.FAMILY_ROOM_PLUS;
    case TentTypeV1.DORM_SINGLE:
      return TentTypeV1Prices.DORM_SINGLE;
    case TentTypeV1.DORM_BUNK:
      return TentTypeV1Prices.DORM_BUNK;
  }
};

export const bathingPremium = 500;

export const getBathingPrice = (tentType: TentTypeV1) => {
  return getPrice(tentType) + bathingPremium;
};

export const getLowestPricedTent = () => {
  return Object.values(TentTypeV1).reduce((lowest, tentType) => {
    const price = getPrice(tentType);
    return price < getPrice(lowest) ? tentType : lowest;
  });
};

export const getLowestPriceTag = () => {
  const tentType = getLowestPricedTent();
  return getPriceTag(tentType);
};

export const getPriceTag = (tentType: TentTypeV1) => {
  const price = getPrice(tentType);
  return `RS. ₹${price.toLocaleString()}`;
};

export const getBathingPriceTag = (tentType: TentTypeV1) => {
  const price = getBathingPrice(tentType);
  return `RS. ₹${price.toLocaleString()}`;
};

export type PriceComputation = {
  total: number;
  totalGst: number;
  totalWithGst: number;
  totalFromNonBathingDays: number;
  totalFromBathingDays: number;
  nights: number;
  nightsBathing: number;
};

export const calculatePrice = (
  startDate: string,
  endDate: string,
  rooms: BookingItem[],
): PriceComputation => {
  const start = parseDate(startDate);
  const end = parseDate(endDate);

  const bathingDaysCount = getBathingDaysInRange(startDate, endDate).length;
  const nonBathingDaysCount = differenceInDays(end, start);

  let totalFromBathingDays = 0;
  let totalFromNonBathingDays = 0;
  for (const [type, count] of rooms) {
    const maxCapacityOfRoom = capacityV1.get(type) as CapacityCount;
    totalFromBathingDays += getBathingPrice(type) * bathingDaysCount * maxCapacityOfRoom;
    totalFromNonBathingDays +=
      getPrice(type) *
      count *
      Math.abs(nonBathingDaysCount - bathingDaysCount) *
      maxCapacityOfRoom;
  }

  const total = totalFromNonBathingDays + totalFromBathingDays;
  const totalGst = total * 0.05;

  return {
    total,
    totalGst,
    totalWithGst: total + totalGst,
    totalFromNonBathingDays,
    totalFromBathingDays,
    nights: nonBathingDaysCount,
    nightsBathing: bathingDaysCount,
  };
};
