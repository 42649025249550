import { TentTypeV1 } from '@camp67/model';
import {
  CapacityCount,
  RoomCount,
  capacityCount,
  roomCount,
} from './booking-configuration-types';

export const capacityV1 = new Map<TentTypeV1, CapacityCount>([
  [TentTypeV1.TWIN_ROOM, capacityCount(2)],
  [TentTypeV1.FAMILY_ROOM, capacityCount(4)],
  [TentTypeV1.FAMILY_ROOM_PLUS, capacityCount(6)],
  [TentTypeV1.DORM_SINGLE, capacityCount(1)],
  [TentTypeV1.DORM_BUNK, capacityCount(2)],
]);

export const inventoryV1 = new Map<TentTypeV1, RoomCount>([
  [TentTypeV1.TWIN_ROOM, roomCount(24)],
  [TentTypeV1.FAMILY_ROOM, roomCount(60)],
  [TentTypeV1.FAMILY_ROOM_PLUS, roomCount(60)],
  [TentTypeV1.DORM_SINGLE, roomCount(48)],
  [TentTypeV1.DORM_BUNK, roomCount(64)],
]);
